//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, Ellipsis } from '@/components'
import { codeGeneratePage, codeGenerateDelete, codeGenerateRunDown, codeGenerateRunLocal } from '@/api/modular/gen/codeGenerateManage'
import addForm from './addForm'
import editForm from './editForm'
import indexConfig from './indexConfig'
import indexCodePreview from './indexCodePreview'

export default {
  components: {
    indexConfig,
    indexCodePreview,
    STable,
    Ellipsis,
    addForm,
    editForm
  },
  data () {
    return {
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '表名稱',
          dataIndex: 'tableName',
          scopedSlots: { customRender: 'tableName' }
        },
        {
          title: '代碼包名',
          dataIndex: 'packageName',
          scopedSlots: { customRender: 'packageName' }
        },
        {
          title: '業務名',
          dataIndex: 'busName',
          scopedSlots: { customRender: 'busName' }
        },
        {
          title: '類名',
          dataIndex: 'className',
          scopedSlots: { customRender: 'className' }
        },
        {
          title: '功能名',
          dataIndex: 'tableComment',
          scopedSlots: { customRender: 'tableComment' }
        },
        {
          title: '作者姓名',
          dataIndex: 'authorName'
        },
        {
          title: '表前綴移除',
          dataIndex: 'tablePrefix',
          scopedSlots: { customRender: 'tablePrefix' }
        },
        {
          title: '生成方式',
          dataIndex: 'generateType',
          scopedSlots: { customRender: 'generateType' }
        }
      ],
      tstyle: { 'padding-bottom': '0px', 'margin-bottom': '10px' },
      loadData: parameter => {
        return codeGeneratePage(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      Loading: false,
      jdbcDriverList: [],
      indexOpenShow: true
    }
  },
  created () {
    if (this.hasPerm('codeGenerate:edit') || this.hasPerm('codeGenerate:delete')) {
      this.columns.push({
        title: '操作',
        width: '230px',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
      })
    }
  },
  methods: {
    /**
     * 開始生成代碼（生成壓縮包）
     */
    runDownCodeGenerate (record) {
      this.Loading = true
      codeGenerateRunDown({ id: record.id }).then((res) => {
        this.Loading = false
        this.downloadfile(res)
        // eslint-disable-next-line handle-callback-err
      }).catch((err) => {
        this.Loading = false
        this.$message.error('下載錯誤：獲取文件流錯誤')
      })
    },
    downloadfile (res) {
      var blob = new Blob([res.data], { type: 'application/octet-stream;charset=UTF-8' })
      var contentDisposition = res.headers['content-disposition']
      var patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
      var result = patt.exec(contentDisposition)
      var filename = result[1]
      var downloadElement = document.createElement('a')
      var href = window.URL.createObjectURL(blob) // 創建下載的鏈接
      var reg = /^["](.*)["]$/g
      downloadElement.style.display = 'none'
      downloadElement.href = href
      downloadElement.download = decodeURI(filename.replace(reg, '$1')) // 下載後文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() // 點擊下載
      document.body.removeChild(downloadElement) // 下載完成移除元素
      window.URL.revokeObjectURL(href)
    },
    /**
     * 開始生成代碼（本地項目）
     */
    runLocalCodeGenerate (record) {
      codeGenerateRunLocal(record).then((res) => {
        if (res.success) {
          this.$message.success('生成成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('生成失敗：' + res.message)
        }
      })
    },
    /**
     * 刪除
     */
    codeGenerateDelete (record) {
      this.Loading = true
      codeGenerateDelete([{ id: record.id }]).then((res) => {
        if (res.success) {
          this.$message.success('刪除成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('刪除失敗：' + res.message)
        }
      }).catch((err) => {
        this.$message.error('刪除錯誤：' + err.message)
      }).finally((res) => {
        this.Loading = false
      })
    },
    /**
     * 打開配置界面
     */
    indexConfigOpen (record) {
      this.indexOpenShow = false
      this.$refs.indexConfig.open(record)
    },
    /**
     * 打開預覽界面
     */
    indexCodePreviewOpen (record) {
      this.indexOpenShow = false
      this.$refs.indexCodePreview.open(record)
    },
    /**
     * 詳細配置界面返回
     */
    handleResetOpen () {
      this.indexOpenShow = true
      this.$refs.table.refresh()
    },
    /**
     * 其他界面返回
     */
    handleOk () {
      this.$refs.table.refresh()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
