//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysCodeGenerateConfigList, sysCodeGenerateConfigEdit } from '@/api/modular/gen/sysCodeGenerateConfigManage'
export default {
  data () {
    return {
      // 表頭
      columns: [
        {
          title: 'java字段',
          dataIndex: 'javaName'
        },
        {
          title: '字段描述',
          dataIndex: 'columnComment',
          scopedSlots: { customRender: 'columnComment' }
        },
        {
          title: '物理類型',
          dataIndex: 'dataType'
        },
        {
          title: 'java類型',
          dataIndex: 'javaType',
          scopedSlots: { customRender: 'javaType' }
        },
        {
          title: '作用類型',
          dataIndex: 'effectType',
          scopedSlots: { customRender: 'effectType' }
        },
        {
          title: '字典',
          dataIndex: 'dictTypeCode',
          scopedSlots: { customRender: 'dictTypeCode' }
        },
        {
          title: '列表顯示',
          align: 'center',
          dataIndex: 'whetherTable',
          scopedSlots: { customRender: 'whetherTable' }
        },
        {
          title: '列字段省略',
          align: 'center',
          dataIndex: 'whetherRetract',
          scopedSlots: { customRender: 'whetherRetract' }
        },
        {
          title: '增改',
          align: 'center',
          dataIndex: 'whetherAddUpdate',
          scopedSlots: { customRender: 'whetherAddUpdate' }
        },
        {
          title: '必填',
          align: 'center',
          dataIndex: 'whetherRequired',
          scopedSlots: { customRender: 'whetherRequired' }
        },
        {
          title: '是否是查詢',
          align: 'center',
          dataIndex: 'queryWhether',
          scopedSlots: { customRender: 'queryWhether' }
        },
        {
          title: '查詢方式',
          dataIndex: 'queryType',
          scopedSlots: { customRender: 'queryType' }
        }
      ],
      indexConfigShow: false,
      tableLoading: false,
      visible: false,
      loadData: [],
      javaTypeData: [],
      effectTypeData: [],
      dictDataAll: [],
      codeGenQueryTypeData: [],
      yesOrNoData: []
    }
  },
  methods: {
    /**
     * 打開界面
     */
    open (data) {
      this.indexConfigShow = true
      this.tableLoading = true
      const dictOption = this.$options
      this.javaTypeData = dictOption.filters['dictData']('code_gen_java_type')
      this.effectTypeData = dictOption.filters['dictData']('code_gen_effect_type')
      this.dictDataAll = dictOption.filters['dictDataAll']()
      this.yesOrNoData = dictOption.filters['dictData']('yes_or_no')
      this.codeGenQueryTypeData = dictOption.filters['dictData']('code_gen_query_type')
      const params = {
        codeGenId: data.id
      }
      sysCodeGenerateConfigList(params).then((res) => {
        this.loadData = res.data
        this.loadData.forEach(item => {
          for (const key in item) {
            if (item[key] === 'Y') {
              item[key] = true
            }
            if (item[key] === 'N') {
              item[key] = false
            }
          }
        })
        this.tableLoading = false
      })
    },
    /**
     * 提交表單
     */
    handleSubmit () {
      this.tableLoading = true
      // 做數組屬性轉換, 咱先來一個切斷雙向綁定，學習的童鞋下回記下啊
      // eslint-disable-next-line prefer-const
      let loadDatas = JSON.parse(JSON.stringify(this.loadData))
      loadDatas.forEach(item => {
        // 必填那一項轉換
        for (const key in item) {
          if (item[key] === true) {
            item[key] = 'Y'
          }
          if (item[key] === false) {
            item[key] = 'N'
          }
        }
      })
      const param = {
        sysCodeGenerateConfigParamList: loadDatas
      }
      sysCodeGenerateConfigEdit(param).then((res) => {
        this.tableLoading = false
        if (res.success) {
          this.$message.success('編輯成功')
          this.handleCancel()
        } else {
          this.$message.error('編輯失敗：' + res.message)
        }
      })
    },
    /**
     * 判斷是否（用於是否能選擇或輸入等）
     */
    judgeColumns (data) {
      if (data.columnName.indexOf('create_user') > -1 ||
          data.columnName.indexOf('create_time') > -1 ||
          data.columnName.indexOf('update_user') > -1 ||
          data.columnName.indexOf('update_time') > -1 ||
          data.columnKey === 'PRI') {
        return true
      }
      return false
    },
    handleCancel () {
      this.$emit('ok')
      this.loadData = []
      this.indexConfigShow = false
    }
  }
}
