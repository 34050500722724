//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { codeGenerateInformationList, codeGenerateAdd } from '@/api/modular/gen/codeGenerateManage'
import { getAppList } from '@/api/modular/system/appManage'
import { getMenuTree } from '@/api/modular/system/menuManage'
export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      tableNameData: [],
      tablePrefixData: [],
      generateTypeData: [],
      confirmLoading: false,
      tablePrefixValue: 'N',
      tableNameValue: '',
      packageNameShow: true,
      appData: [],
      menuTreeData: [],
      form: this.$form.createForm(this)
    }
  },
  methods: {
    // 初始化方法
    add () {
      this.visible = true
      this.codeGenerateInformationList()
      this.dataTypeItem()
      this.selectedByDefault()
      this.getSysApplist()
    },
    /**
     * 默認選中項
     */
    selectedByDefault () {
      this.form.getFieldDecorator('packageName', { initialValue: 'vip.xiaonuo' })
      this.form.getFieldDecorator('tablePrefix', { valuePropName: 'checked', initialValue: 'N' })
      this.form.getFieldDecorator('generateType', { valuePropName: 'checked', initialValue: '1' })
      this.tablePrefixValue = 'N'
    },
    /**
     * 獲得所有數據庫的表
     */
    codeGenerateInformationList () {
      codeGenerateInformationList().then((res) => {
        this.tableNameData = res.data
      })
    },
    /**
     * 獲取應用列表
     */
    getSysApplist () {
      return getAppList().then((res) => {
        if (res.success) {
          this.appData = res.data
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    /**
     * 通過應用獲取菜單
     */
    changeApplication (value) {
      this.form.resetFields(`menuPid`, [])
      getMenuTree({ 'application': value }).then((res) => {
        if (res.success) {
          this.menuTreeData = [{
            'id': '-1',
            'parentId': '0',
            'title': '頂級',
            'value': '0',
            'pid': '0',
            'children': res.data
          }]
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    /**
     * 獲取字典數據
     */
    dataTypeItem () {
      this.tablePrefixData = this.$options.filters['dictData']('yes_or_no')
      this.generateTypeData = this.$options.filters['dictData']('code_gen_create_type')
    },
    /**
     * 提交表單
     */
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((errors, values) => {
        if (!errors) {
          this.confirmLoading = true
          codeGenerateAdd(values).then((res) => {
            if (res.success) {
              this.$message.success('新增成功')
              this.$emit('ok', values)
              this.handleCancel()
            } else {
              this.$message.error('新增失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      this.visible = false
      // 清空他們三個
      this.form.getFieldDecorator('className', { initialValue: '' })
      this.form.getFieldDecorator('busName', { initialValue: '' })
      this.form.getFieldDecorator('tableComment', { initialValue: '' })
    },
    /**
     * 選擇數據庫列表
     */
    tableNameSele (item) {
      this.tableNameValue = item.tableName
      this.form.getFieldDecorator('tableComment', { initialValue: item.tableComment })
      this.settingDefaultValue()
    },
    /**
     * 選擇是否移除前綴觸發
     */
    tablePrefixRadio (tablePrefixType) {
      this.tablePrefixValue = tablePrefixType
      this.settingDefaultValue()
    },
    /**
     * 設置默認值
     */
    settingDefaultValue () {
      const tableName = this.classNameToHump()
      this.form.getFieldDecorator('className', { initialValue: tableName })
      this.form.getFieldDecorator('busName', { initialValue: tableName.toLowerCase() })
    },
    /**
     * 設置類名為數據庫表的駝峰命名
     */
    classNameToHump () {
      const arr = this.tableNameValue.toLowerCase().split('_')
      if (this.tablePrefixValue === 'Y') {
        arr.splice(0, 1)
      }
      for (let i = 0; i < arr.length; i++) {
        // charAt()方法得到第一個字母，slice()得到第二個字母以後的字符串
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
      }
      return arr.join('')
    },
    /**
     * 選擇生成方式
     */
    generateTypeRadio (generateType) {
      if (generateType === '1') {
        this.packageNameShow = true
      } else {
        this.packageNameShow = false
        this.form.setFieldsValue({ packageName: 'vip.xiaonuo' })
      }
    }
  }
}
